@import "src/content/styles/embla-variables";

.body-container > nav {
    &.navbar.public-top-menu {
        padding: 0.5rem 1rem;
    }
    .max-container {
        max-width: 100vw;
    }
}
