/*
    http://bootsnipp.com/snippets/featured/responsive-navigation-menu
*/
@import "embla-variables";

$bottom-menu-height: 55px;
$top-bar-height: 55px;

$break-lg: 1200px;
$break-md: 992px;
$break-sm: 768px;
$break-xs: 480px;

$left-side-menu-width: 250px !default;
$left-side-menu-transition-duration: 0.3s !default;
$header-border-color: #eee;
$header-border-color-dark: darken($header-border-color, 10%);

//colors
$userrole-yellow: $color-warning-dark;
$userrole-green: $color-secondary-dark;
$userrole-green-faded: #12a17c5e;
$userrole-blue: #4A71CE;

$userrole-blue-background: #edf1fa;
$userrole-green-background: #eafcf8;

$color-anonymiseret-forloeb: hsl(38, 90%, 95%);
$color-anonymiseret-forloeb-line: hsl(37, 25%, 95%);
$color-fravaer-forloeb-light: #ffeeee;
$color-fravaer-forloeb: #FFD6D6;
$color-fravaer-forloeb-dark: #ffbcbc;
$color-irrelevant-forloeb: $color-gray-95;
$color-active-forloeb: $color-primary-dark;
$color-active-forloeb-dark: #0e1f4b;

$color-user-avatar-subtle: $userrole-green-faded;
$color-ikkegodkendt-ikkeevalueret: #fda9b9;

$color-alt-slider-1: #800080;
$color-alt-slider-2: #556b2f;
$color-alt-slider-3: #008b8b;
