@import "src/content/styles/variables";

$font-family: sans-serif; //Need to define font-family, else the pdf will be formatted wierdly.
$border: 1px solid $gray-dark;

$font-size: 12px;
$font-size-table: 10px;

.pdf-brevskabelon-header {
    font-family: $font-family;
    margin-bottom: 20px;
    border-bottom: $border;
    font-weight: 500;
}

.pdf-brevskabelon-body {
    font-family: $font-family;
    font-size: $font-size; // Targets text not in any html tags

    // Copied from styling from tui-editor-contents (would break line breaks otherwise)
    line-height: 160%;
    box-sizing: content-box;

    // Tui-editor markdown "bold" translates to "h6":
    h6 {
        font-family: $font-family;
        font-weight: bold;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    p {
        font-size: $font-size;
    }

    p > span {
        font-size: $font-size;
    }

    table {
        display: table;
        word-break: break-word;
        width: 100%;
        font-family: $font-family;

        thead > tr > th, 
        tbody > tr > td {
            min-width: 50px;
            padding: 5px 9px 5px 7px;
            border: $border;
            font-size: $font-size-table;
            
        }

        // First column (td) should never break words
        tr td:first-child {
            white-space: nowrap;
        }

        thead > tr, tbody > tr {
            page-break-inside: avoid !important;
        }
    
        // First th in each row
        thead > tr > th:first-child {
            border-right: 0;
        }

        // All but first th in each row
        thead > tr > th:not(:first-child) {
            border-left: 0;
        }

        // First td in each row
        tbody > tr > td:first-child {
            border-right: 0;
            border-top: 0;
        }

        // All but first td in each row
        tbody > tr > td:not(:first-child) {
            border-left: 0;
            border-top: 0
        }
    }
}
