@import "src/content/styles/embla-variables";

body .left-menu {
  z-index: 1033;
}

.left-menu-header-toggle .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 21px;
}

body .left-menu .left-menu-links {
  height: 100%;
  padding: 0 0 61px 0;
}

body.menu-hidden .left-menu {
  min-width: 52px;
  width: 52px;
  overflow: visible;

  .left-menu-links{
    width: 52px;
  }
}

.left-menu-links{
  width: 0;
  transition: width 0.25s ease-out, min-width 0.25s ease-out;
  transition-property: width, min-width;
  transition-duration: 0.25s, 0.25s;
  transition-timing-function: ease-out, ease-out;
  transition-delay: 0s, 0s;

  .notification-item {
    background-color: green;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
}

.left-menu-spinner {
  margin-left: 16px;
  margin-bottom: 24px;

  h3 {
    font-size: 14px;
  }
}

.bottom-menu-contex-menu {
  max-width: 30px;
  max-height: 30px;
  align-self: center;
}