@import "src/content/styles/variables";

.bottom-menu {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  height: $bottom-menu-height;

  background-color: $color-white;
  border-top: $default-border;

  > div {
    height: 100%;
  }

  .menu-link {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #333;

    &.active {
      border-top: 3px solid $color-primary;
      padding-bottom: 3px;
      color: $color-primary;

      svg {
        fill: $color-primary;
      }
    }

    .menu-link-item {
      flex-direction: column;
      align-items: center;
    }
  }
}

.bottom-menu-contex-menu-wrapper {
  align-self: center;
}

.bottom-menu-contex-menu {
  max-width: 30px;
  max-height: 30px;
  align-self: center;
}

.line {
  margin-top: 0;
  margin-bottom: 0;
  color: $gray-light;
  border-top: 1px solid;
}