.custom-tooltip {
  background-color: hsla(0,0%, 100%,.9);
  border: 0.5px solid gray;
  line-height: 2px;
  padding: 10px;

  .custom-tooltip-title-number{
    font-weight: 600;
  }

  .custom-tooltip-title-question{
    color: #333333;
  }

  .custom-tooltip-dataset{
    -webkit-text-fill-color: var(--dataset-color);
  }
}
