@import "src/content/styles/embla-variables";

@media print {
  #create-evalueringsstatistik-container {
    page: portrait-A4;

    #create-dataset-btn, #graph-filter-dropdowns-container, .dataset-actions, #pagination-btn-group, #uddannelsessted-filter *  {
      display: none;
    }

    // Dont show borders on main card:
    > .card {
      border: none;
    }

    .graph-container, .graph-mobile-container {
      // Fix graph size:
      padding: 0 !important;
      min-height: unset !important; //Remove space down to datasets (".graph-mobile-container" has a "min-height: 350px")
      height: fit-content !important;

      // Accesibility (font, lines, etc.)
      font-size: large;

      // Fix graph size (https://github.com/recharts/recharts/issues/1114)
      .recharts-responsive-container {
        height: fit-content !important;
        .recharts-wrapper,
        .recharts-surface{
          width: calc(100%)!important;
        }
      }

      // Dont display tooltips and hover effects:
      .custom-tooltip *,
      .recharts-tooltip-wrapper *,
      .recharts-surface > path {
        display: none;
      }
    }

    // Show extra datasets (for front-page):
    .dataset-card {
      page-break-before: always;

      &.dataset-print {
        page-break-before: initial;
        display: block;
      }
    }
  }
}

.dataset-card {
  &.dataset-print {
    display: none;
  }
}

.evalueringsstatistik-list-item {
  break-inside: avoid;
}

.tab-page-break {
  break-before: page;
}
