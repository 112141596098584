@import "src/content/styles/variables";
@import "src/content/styles/embla-variables";

#userMenu-modal {
    .user-menu-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        color: black;
        flex-direction: row;
    }

    .modal-body {
        padding: 0;

        a:hover {
            text-decoration: none;
            background-color: $color-tertiary-dark;
        }
    }

    .sub-menu {
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        padding-right: 1rem;
    }

    .role-switch-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        background-color: $userrole-green-background;

        svg {
            height: 1rem;
            width: 1rem;
            fill: $userrole-green;
            margin-top: -$margin-xs;
        }

        &.userrole-blue {
            background-color: $userrole-blue-background;

            svg {
                fill: $color-primary-dark;
            }
        }
    }
}
