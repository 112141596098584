@import "src/content/styles/embla-variables";

.file-info-container {
  max-width: 350px;
  margin: 8px;
}

.file-info-container-mobile {
  max-width: 190px;
  margin: 8px;
}

.file-list-component-checkbox-label {
  margin-top: -1rem;
}
