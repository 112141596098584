@import "src/content/styles/variables";

.userrole-avatar {
  height: 100px;
  width: 100px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  &.userrole-green {
    background-color: $userrole-green;
  }

  &.userrole-blue {
    background-color: $userrole-blue;
  }

  &.userrole-yellow {
    background-color: $userrole-yellow;
  }

  p.profile-initials {
    font-size: 48px;
    color: white;
    margin: 0;
  }
}
.user-profile-avatar-wrapper {
    position: relative;

  .user-profile-avatar {
        height: 136px;
        width: 136px;
        border-radius: 80px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.userrole-blue {
            background-color: $userrole-blue;
        }

        &.userrole-green {
            background-color: $userrole-green;
        }

        &.userrole-yellow {
            background-color: $userrole-yellow;
        }

        svg {
            fill: white;
            color: white;
        }

        p.profile-initials {
            font-size: 48px;
            color: white;
            margin: 0;
        }

        &.medium {
            height: 70px;
            width: 70px;

            > p.profile-initials {
                font-size: 20px;
            }
        }

        &.small {
            height: 40px;
            width: 40px;

            > p.profile-initials {
                font-size: 14px;
            }
        }

        &.extra-small {
            height: 25px;
            width: 25px;

            > p.profile-initials {
                font-size: 11px;
            }
        }

        &.extra-extra-small {
            height: 20px;
            width: 20px;

            > p.profile-initials {
                font-size: 9px;
            }
        }
    }
}

.user-profile-name {
    margin-left: $margin-xs;
}

.image-upload-type-icon {
  position: absolute;
  right: 4%;
  bottom: 4%;
  .image-upload-type-icon-hover {
    &:hover {
      background-color: $gray-light;
    }
  }
}

.image-background {
  background-color: $color-tertiary-light;
}

.confirm-deny-image {
  background-color: $color-tertiary-light;
  border-radius: 5px;
}

.btn-danger.btn-outline,
.btn-danger.btn-outline:hover,
.btn-success.btn-outline,
.btn-success.btn-outline:hover {
  &.confirm-deny-image {
    background-color: $color-tertiary-light;
  }
}

.image-tooltip-center {
  justify-items: center;
}
.image-tooltip-override {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #D6DFF5;
  --rt-opacity: 1;
  --rt-transition-show-delay: 0.3s;
  --rt-transition-closing-delay: 0.3s;
  z-index: 99999 !important;
  border: 2px solid $color-gray-85;
  border-radius: 10px !important;
  pointer-events: none;
}

.arrow-classes {
  border-right: 2px solid $color-gray-85 !important;
  border-bottom: 2px solid $color-gray-85 !important;
  width: 5.5px !important;
  height: 5.5px !important;
}
