@import "src/content/styles/variables";

/* add variables to be used by style config inside typescript */
.dropdown {
  --dropdown-height: #{$dropdown-height};
  --dropdown-font-size: #{$font-size-medium};
  --dropdown-line-height: #{$line-height-medium};
  --dropdown-border-color: #{$input-border-color};
  --dropdown-placeholder-color: #{$input-color-placeholder};
  --dropdown-indicator-color: #{$icon-color};
  --dropdown-caret-open-color: #{$color-primary};
  --dropdown-focus-shadow: #{$outline-box-shadow rgba($input-border-color, $outline-border-opacity)};
  --dropdown-selected-item-background-color: #{$dropdown-item-selected-background-color};
  --dropdown-selected-item-border-color: #{$dropdown-item-selected-border-color};
  --dropdown-item-highlighted-background-color: #{$dropdown-item-highlighted-background-color};
  --dropdown-multi-item-selected-background-color: #{$dropdown-multi-item-selected-background-color};
  --dropdown-multi-item-selected-background-color-as-subjects: #{$color-secondary};
  --dropdown-multi-item-selected-disabled-background-color: #{$dropdown-multi-item-selected-disabled-background-color};
  --dropdown-multi-item-selected-text-color: #{$dropdown-multi-item-selected-text-color};
  --dropdown-disabled-background-color: #{$dropdown-disabled-background-color};

  &:global(.input-validation-error) {
    --dropdown-border-color: #{$validation-error};
  }
}
