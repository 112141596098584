@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";

.landing-page-white-area .card .card-body {
  max-width: 350px;
}

.landing-page-white-area {
  margin: 0 -47px;
  background-color: $color-white;

  .landing-page-bottom {
    margin-bottom: -64px;

    .landing-page-bottom-margin {
      margin-bottom: $margin-l;
      padding: 0 2em;
    }
  }
}

.landing-page-colored-area {
  padding-bottom: 1.5rem;
}

.landing-page-image-container {
  height: 443px;
  width: 666px;
}

.denmark-map {
  height: 420px;
  width: 400px;

  svg {
    path {
      cursor: pointer;
    }

    path#RegionNordjylland:not(.active-region):hover,
    path#RegionMidtjylland:not(.active-region):hover,
    path#RegionSyddanmark:not(.active-region):hover,
    path#RegionHovedstaden:not(.active-region):hover,
    path#RegionSjaelland:not(.active-region):hover {
      opacity: 0.5;
    }
  }
}

.active-region {
  opacity: 0.7;
}

.region-container {
  height: fit-content;
  width: 44%;
}

.driftsforstyrrelse-container {
  height: fit-content;
  width: 666px;
}

.landing-page-welcome {
  max-width: 400px;

  .btn-secondary {
    color: $color-gray-15;

    .embla-icon {
      fill: $color-gray-15;
    }
  }
}

.landing-page-image-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page-contact-info {
  width: 60%;
  text-align: center;
  a {
    font-weight : bolder;
  }
}

@media screen and (max-width: 992px) {
  .region-container {
    width: 70%;
    margin-top: 2rem;
  }

  .landing-page-contact-info {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .landing-page-white-area {
    margin: 0 -15px;
    justify-content: center;
    min-width: auto;

    .landing-page-user-cards .flex-space {
      place-content: center
    }
  }

  .btn-landing-page-login {
    width: 100%;
  }

  .landing-page-image-container {
    display: none;
  }

  .denmark-map {
    display: none;
  }

  .region-container {
    width: 100%;
    margin-top: 0;
  }

  .landing-page-contact-info {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .landing-page-bottom-brand {
    margin-top: 1rem;
  }
}
