@import "src/content/styles/embla-variables";

@mixin truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-avatar-wrapper {
    display: flex;
    max-height: 40px;

    &.top-menu-button {
        margin-left: auto;
        cursor: pointer;

        .user-name-and-role {
            max-width: 9.5rem;
        }
    }

    &.with-name {
        .user-profile-avatar-wrapper {
            background-color: $color-primary-dark;
            border-radius: 50% 0 0 50%;
        }
    }
}

.user-name-and-role {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 18rem;
    padding: 0 0.5rem;
    border-radius: 0 2rem 2rem 0;
    background-color: $color-primary-dark;

    .title {
        @include truncate();
        line-height: 1rem;
        color: $color-white;
        padding-right: $padding-s;
    }

    .subtitle {
        @include truncate();
        line-height: 1rem;
        font-size: 0.7rem;
        color: $color-gray-70;
        padding-right: $padding-s;
        margin: 0;
    }
}
