@import "src/content/styles/embla-variables";

.back-button {
  color: $color-white;
}

.top-menu-logo-link {
    &:hover {
        text-decoration: none;
    }
}

.top-menu-title {
  display: flex;
  align-items: center;

  div {
    font-size: 12px;
    color: $color-white;
    opacity: 0.7;
  }

  h4 {
    color: $color-white
  }
}

.public-top-menu {
  display: flex;

  h5 {
    color: $color-white;
    align-self: center;
  }
  h6 {
    color: $color-white;
    align-self: center;
  }
  .btn-secondary {
    color: $color-gray-15;
  }
}

.impersonation-border {
  border: 2px solid $color-danger-dark !important;
}

.logo-titel {
  margin-right: var(--margin-right-logo);
  .title-color {
    color: $color-white;
  }
}

.driftsforstyrrelser-tekst {
  margin-left: var(--margin-left-driftsforstyrrelser);
  color:  $color-white;
  font-weight: bold;
  font-size: 12px;
}

.top-menu-right-wrapper {
  display: flex;
  max-height: 40px;
  margin-left: auto;
  align-items: center;

  a {
    padding-right: 0.5rem;
  }
}
