@import "src/content/styles/embla-variables";

.progress {
  border-radius: 10px;
  background-color: #f1f1f1;
  margin-bottom: 0.2rem;
  height: 0.5rem;


  .progress-bar {
    border-radius: 0;
    width: var(--progressPercentage);
  }

  .progress-bar:first-child {
    border-radius: 10px 0 0 10px;
  }

  .progress-bar:last-child {
    border-radius: 0 10px 10px 0;
  }

}

.badge-progressBadge {
  border: 1px solid lightgrey;

  .progressBadgeStatus{
    margin-top: -1px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }
}

.progressColor-info{
  background-color: $color-info;
}
.progressColor-warning{
  background-color: $color-warning;
}
.progressColor-danger{
  background-color: $color-danger;
}
.progressColor-primary{
  background-color: $color-primary;
}
.progressColor-secondary{
  background-color: $color-secondary;
}
.progressColor-success{
  background-color: $color-success;
}
.progressColor-gray{
  background-color: $color-gray-95;
}
