﻿
//transparent
.btn.btn-transparent {
  color: $color-secondary;
  background-color: transparent;
  border: $default-border;

  svg.embla-icon {
    fill: $color-secondary;
  }

  svg {
    fill: $color-secondary;
  }
}
.btn.btn-transparent:hover {
  color: $color-secondary;
  text-decoration: none;
}

.btn.btn-round-circle {
  width: 2rem;
}

//round
.btn.btn-round {
  border-radius: 100px;

  &.btn-no-right-border {
    border-radius: 100px 0 0 100px;
    border-right-style: none;
  }
}

.btn.btn-datepicker {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  border-radius: 0 4px 4px 0;
  border-color: $gray-dark;
  border-left: 0;
}
