@import "src/content/styles/embla-variables";

.circle-container {
  overflow: hidden;
}

.circle-container img {
  position: absolute;
  border-radius: 50% !important;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
}

.crop-container {
  position: relative;
  width: 100%;
  height: 425px;
}

.crop-container-border {
  border-radius: 5px;
}

.delete-btn-image {
  position: absolute;
}

.image-hover {
  border-radius: 50% !important;
  height: 200px;
}
