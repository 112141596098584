@import "src/content/styles/embla-variables";

.godkend-info-mobile {
  margin-left: -1rem;
  margin-right: -1rem;
}

.white-svar {
  color: white;
}
