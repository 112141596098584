@import "src/content/styles/embla-variables";

.notification-button-wrapper {

  .notification-button {
    position: relative;
    height: 100%;
  
    .notification-counter {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.mark-all-as-read-btn {
  border: none;
  color: white;
  font-size: x-small;

  &:hover {
    color: $color-tertiary-dark;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
