@import "src/content/styles/embla-variables";

.input-icon-container {
  position: relative;

  .input-icon {
    position: absolute;
    right: 10px;
    top: 0;
    height: 32px;
    background-color: transparent;

    &.success {
      svg {
        fill: forestgreen;
      }
    }

    &.warning {
      svg {
        fill: $color-danger-dark;
      }
    }

    &.spinner {
      height: 10px;
    }

    svg {
      height: 32px;
    }
  }
}

.mobile-input-container {
  flex-grow: 1;
  margin-top:   .5rem;
  margin-right: .5rem;
  margin-left:  .5rem;
  margin-bottom:  .5rem;
}

.sticky-top {
  position: sticky;
  top: calc($top-bar-height - .5rem);
  z-index: 3;
}