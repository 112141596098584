﻿@import "src/content/styles/variables";

$background-color: rgba(241, 245, 248, .5);

a {
    cursor: pointer;
}

.a-style {
    color: $color-primary;
    cursor: pointer;
}

.error-actions {
    margin-top: 20px;
}

.error-id {
    margin-top: 10px;
}

.align-items-center {
    align-items: center;
}

p.no-margin-bottom {
    margin-bottom: 0;
}

@-webkit-keyframes fontfix {
    from {
        opacity: 1;
    }

    to {
        opacity: 1;
    }
}

div.word-wrap {
    word-wrap: break-word;
}

.clickable-row {
    cursor: pointer;
}

.exclude-click-event {
    cursor: default !important;
}

.min-w-60 {
    min-width: 60px;
}

.min-w-140 {
    min-width: 140px;
}

.min-w-150 {
  min-width: 150px;
}

.min-w-200 {
    min-width: 200px;
}

.min-w-300 {
    min-width: 300px;
}

.min-w-500 {
    min-width: 500px;
}

.min-w-550 {
    min-width: 550px;
}

.max-w-150 {
    max-width: 150px;
}

.max-w-400 {
    max-width: 400px;
}

.max-w-525 {
    max-width: 525px;
}

.max-w-600 {
    max-width: 600px;
}

.body-content-paddings {
    margin: 32px 64px;
}

.w-150 {
    width: 150px;
}

.left-right-minus-spacing {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
}

.left-right-minus-l-spacing {
    margin-left: -64px;
    margin-right: -64px;
    padding-left: 64px;
    padding-right: 64px;
}

.form-control.mbsc-comp[disabled] {
    background-color: $gray-lighter;

    + .input-group-append > .input-group-text {
        background-color: $gray-lighter !important;
    }
}

// Fix "disabled" for hidden input-field
.visually-hidden.mbsc-comp[disabled] {
    + .input-group-append > .input-group-text {
        pointer-events: none;
        opacity: 0.7;
    }
}

.padding-tbr-s {
    padding-top: 4px;
    padding-top: 0.25rem;

    padding-right: 8px;
    padding-right: 0.5rem;

    padding-bottom: 8px;
    padding-bottom: 0.5rem;
}

.input-group-text.sharp-border {
    border-radius: 0 0 0 0;
}

.w-75 {
    width: 75%;
}

.form-control[readonly].no-gray {
    background-color: white;
}

// Looks like an Anchor tag <a>.
.clickable {
    cursor: pointer;
    color: $color-primary;
    &:hover {
        text-decoration: underline;
        color: $text-color;
    }
}

.clickable-no-hover {
    cursor: pointer;
    color: $color-primary;
}

// Looks like an Anchor tag <a>.
.clickable-no-coloring {
    cursor: pointer;
    color: $color-primary;
    &:hover {
        text-decoration: underline;
    }
}

.visually-hidden {
    max-width: 0px;
    border-width: 0px;
    padding-right: 0;
    pointer-events: none;
}

input:checked + .color-alt-slider-1 {
    background-color: $color-alt-slider-1 !important;
    border-color: $color-alt-slider-1 !important;
}

input:checked + .color-alt-slider-2 {
    background-color: $color-alt-slider-2 !important;
    border-color: $color-alt-slider-2 !important;
}

input:checked + .color-alt-slider-3 {
    background-color: $color-alt-slider-3 !important;
    border-color: $color-alt-slider-3 !important;
}

.color-alt-pill-1 {
    background-color: $color-alt-slider-1 !important;
    border-color: $color-alt-slider-1 !important;
}

.color-alt-pill-2 {
    background-color: $color-alt-slider-2 !important;
    border-color: $color-alt-slider-2 !important;
}

.color-alt-pill-3 {
    background-color: $color-alt-slider-3 !important;
    border-color: $color-alt-slider-3 !important;
}

.bg-fravaer {
    background-color: $color-fravaer-forloeb !important;
}

.bg-warning {
    background-color: $warning-background-color !important;
}
