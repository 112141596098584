@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";



.badge-primary-light {
  background-color: $userrole-blue;
  color: $color-tertiary-light;

  svg.embla-icon {
    fill: $color-tertiary-light;
  }
}

.badge-normal {
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-right: 2px;
  }

  svg.embla-icon {
    vertical-align: top;
    height: 18px;
    width: 18px;
  }

  font-size: 12px;
}

.success {
  background-color: $color-success-light;
  color: $color-success-dark;

  svg.embla-icon {
    fill: $color-success-dark;
  }
}

.warning {
  background-color: $color-danger-light;

  color: $color-danger-dark;

  svg.embla-icon {
    fill: $color-danger-dark;
  }
}
