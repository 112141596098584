.notat-metadata-field {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fit-content-min-width {
  min-width: fit-content;
}

.fit-content {
  width: fit-content;
}

.break-space-pills {
  white-space: break-spaces;
}

.inherit-pills {
  white-space: inherit;
}
