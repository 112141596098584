﻿.pdf-avoid-page-break-inside {
  page-break-inside: avoid;
}

.pdf-page-break {
  page-break-before: always;
}

.pdf-page-break-after {
  page-break-after: always;
}

.pdf-content {
  // Overwrite background-color to white (use padding instead of margin)
  padding-inline: 32px;
  background-color: #fff;
}

.pdf-header {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 24px;
}