@import "src/content/styles/embla-variables";

#notification-settings-overview-modal {
  .modal-header {
      border-bottom: 0;
      background-color: $color-primary-dark;
      color: $color-tertiary-dark;
      .modal-title {
        color: $color-white;
      }
      svg.embla-icon {
        fill: $color-white;
    }
  }
}