﻿.flex-container {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row-center {
  display: flex;
  justify-content: center;
}

.flex-align-c {
  display: flex;
  align-items: center;
}

.flex-align-top {
  display: flex;
  align-items: start;
}

.flex-align-b {
  align-items: baseline;
}

.flex-grow {
  flex-grow: 1;
  min-width: 1px;
}

.flex-full-width {
  display: flex;
  flex-basis: 100%;
}

.flex-container-column {
  display: flex;
  flex-direction: column;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
}

.flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.flex-start {
  display: flex;
  justify-content: flex-start !important;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-align-items-end {
  display: flex;
  align-items: flex-end;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.flex-space-evenly {
    display: flex;
    justify-content: space-evenly;
}

.flex-align-r {
  margin-left: auto;
}

.flex-align-l {
  margin-right: auto;
}

.flex-align-bot {
  margin-top: auto;
}

.flex-align-self-c {
  display: flex;
  margin-left: auto;
}

.flex-space-around {
  margin: -5px;

  > * {
    margin: 5px;
  }
}

.wrap    {
  flex-wrap: wrap;
}
