@import "src/content/styles/embla-variables";

.checkbox-wrapper {
  [type="checkbox"]:not(.switch) + label {
    margin-right: $margin-xs;
  }
}

.card-list {
  &:last-child {
    padding-bottom: 1rem;
    border-bottom: 1px solid #dee2e6;
  }
}
