@import "src/content/styles/embla-variables";

.modal-actions {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: -16px;
  margin-left: -16px;
  margin-top: auto;
  width: calc(100% + 32px);
  background: white;
  border-top: $default-border;
  display: flex;
  justify-content: flex-end;
  > div {
    margin-right: 1rem;
  }
}

.modal-body {
  > * {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.modal-header {
  button.close.left-align {
    padding: 0;
    margin: 0;
    order: 0;
    margin-right: 1rem;
  }
}