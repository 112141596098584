﻿@import "src/content/styles/embla-variables";

@media screen and (max-width: 767px) {
  html {
    height: 100%;

    body {
      height: 100%;

      > .spinner-with-text {
        padding-top: 16px;
        padding-left: 16px;
      }

      #root {
        height: 100%;

        .body-container {
          min-height: 100%;

          .body-content {
            margin-bottom: $bottom-menu-height;
          }
        }
      }
    }
  }

  .back-container {
    margin-left: -9px;
    cursor: pointer;
  }
}

.body-container > nav {
  &.navbar.mobile-header {
    padding: 0.0rem 1rem 0.0rem 1rem;
  }
}


.body-content {
  .top-level-container {
    margin-bottom: 0;
  }

  &.fullscreen {
    padding: 0;
    margin: 0;

    .datatable-pretty-init {
      .card {
        border: 0;
      }

      .table-row {
        margin-right: 0;
        margin-left: 0;

        .col {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    > .top-level-row {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;

      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }

    > .spinner-with-text {
      padding-top: 64px;
      padding-left: 64px;
    }

    .top-level-container {
      border-left: 0;
      border-right: 0;
    }

    .top-level-container-margins {
      margin: $margin-m;
    }
  }
}
