@import "src/content/styles/variables";
@import "src/content/styles/embla-variables";


.uddannelseslaege-kompetencer-circle{
  position: relative;

  .uddannelseslaege-kompetencer-image{
    height: 1.5rem !important;
    width: 1.5rem !important;
    fill: $color-primary;
  }

  .uddannelseslaege-kompetencer-counter{
    position: absolute;
    height: 1rem;
    width: 1rem;
    top:-20%;
    right: -20%;
    background-color: deeppink;

    .uddannelseslaege-kompetencer-text{
      position: absolute;
      font-size: 11px;
      top:-15%;
      right:25%;
      color: white;
    }
  }
}
