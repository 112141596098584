@import "src/content/styles/variables";

$font-size: 12px;
$font-size-table: 10px;

.pdf-preview-container {
    width: 100%;
    background-color: $gray-lighter;

    display: flex;
    justify-content: center;
    align-items: center;

    .pdf-preview {
        width: 210mm; // A4 PDF width
        background-color: $color-white;
        border: 1px solid #DDDDDD;
        overflow: auto;

        p, h6 {
            font-size: $font-size;
        }
    }
}

.styled-tables table {
    display: table;
    width: 100%;
    word-break: break-word;

    p {
        font-size: $font-size-table;
    }

    thead > tr > th {
        background-color: $color-white;
        color: black;
        font-weight: 600; //matches bootstrap bolder
        border: 0;
        font-size: $font-size-table;
        min-width: 50px;
        padding: 5px 9px 5px 7px;
    }

    tbody > tr > td {
        border-left: 0;
        border-right: 0;
        font-size: $font-size-table;
        min-width: 50px;
        padding: 5px 9px 5px 7px;
    }
}
