@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";

.wizard {
  height: calc(95vh - 190px);
  margin: 32px 16px;

}

.wizard-mobile {
  // Fallback for browsers that doesnt support "svh"
  height: calc(100vh - $bottom-menu-height - $top-bar-height);
  // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
  // Use "svh" (Smallest viewport height)
  height: calc(100svh - $bottom-menu-height - $top-bar-height);

  .sticky-header {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: calc($top-bar-height);
    z-index: 1; //Prevent transparency when overscroll on mobile
  }

  .sticky-footer {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: calc($bottom-menu-height);
  }
}

.wizard-steps {
  display: flex;
  padding: 16px 64px;

  .wizard-step {
    display: flex;
    font-size: $wizard-step-font-size;
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }

    .wizard-step-content {

      background-color: $wizard-step-background;
      border-radius: 50%;
      color: $wizard-step-content-font-color;
      font-size: $wizard-step-font-size;
      height: 40px;
      padding-top: 0.35rem;
      text-align: center;
      width: 40px;

      .embla-icon {
        fill: $wizard-step-content-font-color;
      }
    }

    .wizard-step-text {
      align-items: center;
      color: $wizard-step-text-color;
      display: flex;
      font-size: $wizard-step-text-font-size;
      font-weight: $font-weight-bold;
      margin-left: $margin-s;
      white-space: nowrap;

      @media (max-width: $wizard-responsive-breakpoint) {
        display: none;
      }
    }

    &.active-step {
      .wizard-step-content {
        background-color: $wizard-active-step-background;
        color: $wizard-active-step-text-color;

        .embla-icon {
          fill: $wizard-active-step-text-color;
        }
      }

      .wizard-step-text {

        color: $wizard-active-step-background;

        @media (max-width: $wizard-responsive-breakpoint) {
          display: block;
          left: 50%;
          margin-left: 0;
          position: absolute;
          top: 76px;
          transform: translateX(-50%);
        }
      }
    }

    &.done-step {
      .wizard-step-content {
        background-color: $wizard-done-step-background;
        color: $wizard-done-step-text-color;

        .embla-icon {
          fill: $wizard-done-step-text-color;
        }
      }
    }

    @media (max-width: $wizard-responsive-breakpoint) {
      margin: 0 8px;
    }
  }

  @media (max-width: $wizard-responsive-breakpoint) {
    border-bottom: $wizard-border;
    border-right: 0;
    flex-direction: row;
    justify-content: center;
    overflow-x: auto;
    padding-bottom: 64px;
    padding-top: 16px;
  }
}



