@import "src/content/styles/embla-variables";

.tooltip-wrapper {
  display: inline-block; /* display: block works as well */
}

.tooltip-wrapper .btn[disabled] {
  /* don't let button block mouse events from reaching wrapper */
  pointer-events: none;
}

.tooltip-center {
  display: flex;
  align-items: center;
}

.tooltip-inner > ul {
  text-align: left;
}

.tooltip-inner {
  max-width: 225px;
}

