@import "src/content/styles/embla-variables";

.spoergsmaal{

  .card{
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    padding: $padding-s;
    box-shadow: none;
  }

  .card:last-child{
    margin-top: $margin-m;
  }

}

.addCommentBtn[aria-expanded="true"]{
    display: none;
}

.badge-info-light {
  color: #212529;
  background-color: #dbf0fa;
}
