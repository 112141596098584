@import "src/content/styles/embla-variables";

.crud-div {
 border: $default-border;
}

.a-style {
  text-align: right;
}

.remove-margin-top{
  margin-top: -0.75rem !important;
}
