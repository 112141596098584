@import "src/content/styles/embla-variables";

.modal-message .modal-content .modal-body {
  margin-left: 0;
  padding-top: 0;
  margin-top: $margin-m;
  text-align: left;
}

.modal-message .modal-content .modal-footer {
  //border-top: 1px solid #dee2e6;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0.75rem;
}
