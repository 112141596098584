@import "src/content/styles/embla-variables";

#userMenu-modal {
    .modal-header {
        border-bottom: 0;
        background-color: $color-primary-dark;
        color: $color-tertiary-dark;

        .btn-icon {
            svg.embla-icon {
                fill: $color-white;
            }
        }
    }
}
