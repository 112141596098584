@import "src/content/styles/embla-variables";

.emptyInfoCard{
  border:none;
  box-shadow: none;

  background-color: $gray-lighter;
  border-radius: 10px;

  .card-body{
    text-align: center;
  }
}

.folder {
  background-color:$color-tertiary-light;
}

@mixin dynamic-padding($value) {
  padding-left: #{$value}rem;
}

@for $i from 1 through 10 {
  .dynamic-padding-#{$i} {
    @include dynamic-padding($i);
  }
}

