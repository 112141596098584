@use "sass:math";
@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";

.btn-group-max-height {
  max-height: 700px;
}

.dropdown-menu .dropdown-item, .dropdown-menu .dropdown-header .dropdown-group-btn-item {
  padding: 0 0 0 0.5rem;
  align-items: center;
}

.dropdown-item.dropdown-item-mobile {
  text-wrap: wrap;
  height: 100%;
  padding: 0 0 1.5rem 0.5rem
}

.dropdown-group-btn-item-min-height {
  height: 30px;
  align-items: center;
}

.dropdown-menu-padding-0 {
  padding: 0 0;
}

.dropdown-item.item-selected {
  font-weight: 600;
}
