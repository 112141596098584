@import "src/content/styles/variables";

.cookie-consent-banner-container {
  z-index: 9999 !important;
  background-color: rgb(0, 0, 0, 0.8) !important;
  font-size: 14px;

  .cookie-consent-banner-content {
    .content-title {
      color: white;
      margin-bottom: 1rem;
    }
    .content-section {
      font-size: 14px;
      margin-bottom: 1rem;
    }
    .content-link-container {
      display: flex;

      .content-link {
        color: white;
      }
    }
  }

  .cookie-consent-banner-buttons-wrapper {
    padding: 1rem;

    .accept-button {
      font-weight: bold;
      min-width: 85px;

      &:hover {
        background-color: $color-primary !important;
        color: $color-white !important; 

      }
    }
    .decline-button {
      font-weight: bold;
      min-width: 85px;

      &:hover {
        background-color: $color-primary !important;
        color: $color-white !important; 

      }
    }
  }
}

