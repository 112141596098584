@import "src/content/styles/embla-variables";

.square {
  height: 25px;
  width: 25px;
  text-align: center;
  border-radius: 3px;
  margin-right: 5px;
}

.questions-answered {
  background-color: $color-primary-light;
}

.wide-square {
  height: 25px;
  width: 32px;
  text-align: center;
  border-radius: 3px;
  margin-right: 5px;
}

.with-comment:after {
  overflow:hidden;
  content: "";
  position:absolute;
  width: 0;
  height: 0;
  margin-left: 0.5px;
  border-top-right-radius: 3px;
  border-left: 8px solid transparent;
  border-top: 8px solid $color-primary-dark;
}

.score-container {
  display: flex;
}
