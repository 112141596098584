﻿body .left-menu .left-menu-links .left-menu-group div.left-menu-group-separator {
  margin: 0;
}

.left-menu-group-separator-margin {
  margin: 24px 12px;
}

.left-menu-group-separator-margin-bottom {
  margin-bottom: 24px;
  margin-left: 12px;
  margin-right: 12px;
}

.card .card-body + .card-body {
  border-top: $default-border;
}

.card > .card-body {
  &.padding-0 {
    padding: 0;
  }
}

.nav-item {
  cursor: pointer;
}

.nav.nav-tabs > li.nav-item > a.nav-link {
  padding-left: 15px;
  padding-right: 15px;
}

.body-container > nav {
  &.navbar {
    padding: 0.5rem 1rem 0.5rem 52px;
  }
}

.select2-container .select2-search__field {
  width: 100% !important;
}

.modal-dialog {
  > .modal-content {
    border-radius: 0;
    > .modal-mobile {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
}

.modal-content .modal-header {
  height: 55px !important;
  min-height: 55px !important;
}


//fonts:
body {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }

  .nav-tabs > li.nav-item > a.nav-link {
    font-weight: 600;
  }
}

.datatable-pretty-init{
  &.header-hidden {
    .card-body:first-child {
      padding: 4px;
    }

    tr:first-child {
      th {
        border-top: 0;
      }
    }
  }
}
