.pdf-list-header {
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: start;
}

.pdf-list-row-column-item {
  text-align: start;
  align-self: center;
}

.pdf-list-row-column-item-center {
  text-align: center;
  align-self: center;
}
