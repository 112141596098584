﻿.page-content {
  background-color: $color-tertiary-light;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body.preload * { // ".preload" class is fix for IE transition jump on page loads - it should be removed on $(document).ready(). See https://css-tricks.com/transitions-only-after-page-load/
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

body {
  background-color: $background-color;

  &.sidebar-hidden {

    div#body-wrapper {
      margin-left: 50px;
    }
  }
  /* Trying to fix font not rendering */
  -webkit-animation-delay: 0.1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
}

div#body-wrapper {
  margin-left: $left-side-menu-width;
  transition: margin-left $left-side-menu-transition-duration;
  margin-bottom: 30px;
}

.body-content {
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 0;

  &.body-content-error {
    height: 91vh;
  }
}
