@import "src/content/styles/embla-variables";

.a-style {
  text-align: left;
}

.files-table {

  .card {
    border: none;
    box-shadow: none;

    .card .card-body + .card-body {
      border-top: none;
    }

    > .card-body + .card-body {
      border: none;
    }
  }
}

.aftale-file-picker {
  .inputfile + label {
    width: 25%;
  }
}

.aftale-file-picker-mobile {
  .inputfile + label {
    width: 100%;
  }
}


