@import "src/content/styles/embla-variables";

.counter-circle {
  border-radius: 80px;
  background-color: $color-danger-dark;
  display: flex;
  justify-content: center;
  align-items: center;

  &.color-green {
    background-color: green;
  }

  &.color-primary {
    background-color: $color-primary;
  }

  &.extra-small {
    height: 0.8rem;
    width: 0.8rem;
    font-size: 0.4rem;
  }

  &.small {
    height: 1rem;
    width: 1rem;
    font-size: 0.6rem;
  }
  &.medium {
    height: 1.2rem;
    width: 1.2rem;
    font-size: 0.7rem;
  }
  &.large {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 0.8rem;
  }
  &.auto {
    height: auto;
    width: auto;
  }

  &.hidden {
    display: none;
  }

  &.position-absolute {
    position: absolute;
    top: 0;
    right: 0;
  }

  .count-text {
    color: white;
  }
}
