.beskaeftigelse-pdf-list-header {
  font-weight: bolder;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.beskaeftigelse-pdf-list-item {
  text-align: center;
  align-self: center;
}

.beskaeftigelse-pdf-text-underline {
  text-decoration: underline;
  font-weight: bolder;
}


