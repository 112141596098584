 @import "src/content/styles/embla-variables";
 @import "src/content/styles/variables";

.uddannelseslaege-header{
  background-color: $userrole-green;

  .uddannelseslaege-header-txt{
    color: $color-tertiary-light;
    padding-left: -2rem;
  }

  .uddannelseslaege-header-image{
    opacity: 0.7;
    fill: $color-white;

    &.info{
      opacity: 1;
    }
  }
}
