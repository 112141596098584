@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";

.onlyIcon {
  width: $spacing-l;
}

.fit-content-min-height {
  min-height: fit-content;
}
