﻿.round {
  align-self: center;
  border: $default-border;
  border-radius: 50%;
  min-height: 32px;
  max-height: 32px;
  min-width: 32px;
  max-width: 32px;
  text-align: center;
  padding-top: 2px;

  &.small {
    min-height: 15px;
    max-height: 15px;
    min-width: 15px;
    max-width: 15px;
  }

  svg.embla-icon {
    height: 16px;
    width: 16px;
    fill: $color-primary-light;
  }

  &.success {
    svg.embla-icon {
      height: 16px;
      width: 16px;
      fill: $color-success-dark;
    }
  }

  &.warning {
    svg.embla-icon {
      height: 16px;
      width: 16px;
      fill: $color-danger-dark;
    }
  }
}
