@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";

.forloeb-header {
  background-color: $color-white;

  .forloeb-badges {
    z-index: 100;
  }

  .disabled-button {
    opacity: 0.3;
  }

  svg.water-mark-svg {
    opacity: 0.1;
    position: absolute;
    height: 100px;
    bottom: 0;
  }
  &.irrelevant-forloeb {
    background-color: $color-irrelevant-forloeb;
  }
  &.active-forloeb {
    background-color: $color-active-forloeb;

    .forloeb-header-txt {
      color: $color-tertiary-light;

      > * {
        color: $color-tertiary-light;
      }
    }
  }

  &.active {
    border: 3px $color-secondary solid;
  }
}

.forloeb-uddannelse-circle {
  align-self: center;
  border: $default-border;
  height: 32px;
  width: 32px;
  text-align: center;
  padding-top: 2px;

  svg.embla-icon {
    height: 16px;
    width: 16px;
    fill: $color-secondary;
  }
}

body .body-content {
  padding: 0 32px;
}
