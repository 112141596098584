@use "sass:math";
@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";

@mixin sticky-white {
  position: sticky;
  top: 0;
  left: 0;
  background-color: $color-white;
}

$month-column-width: var(--month-column-width) !default;
$month-overlay-color: rgba(130, 130, 130, 0.1) !default;

@media print {
  #forloeb-overview-container {
    page: landscape-A3-no-margin;

    .forloeb-container {
      visibility: visible;
      height: 100% !important;
      overflow: hidden !important;

      .forloeb-chart {
        height: 99vh !important;
        overflow: hidden !important;

        // Show shadows in PDF:
        .laege-stilling-profile {
          box-shadow: 1px 1px $margin-xs lightgrey !important;
        }
        .laege-afdeling {
          box-shadow: 1px 1px $margin-xs lightgrey !important;
        }
      }
    }

    //Hide page-specific content:
    .card-header *, .pagination {
      display: none;
    }

    //Fix spacing for page-specific content:
    &.top-level-container {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

.forloeb-container {
  height: calc(100vh - 216px);
  background-color: $color-tertiary-light;

  .forloeb-chart {
    height: calc(100vh - 216px);
    display: grid;
    grid-template-rows: repeat(var(--total-row-count), 32px) 32px 1fr; //rows (32px each) + spacer (32px) + pagination (1fr)
    grid-template-columns: min(26em) repeat(var(--total-month-count), $month-column-width);
    overflow-x: auto;

    .forloeb-month-line {
      z-index: 47;
      display: grid;
      grid-row-start: 3;
      grid-row-end: var(--laege-row-index);
      grid-column-start: var(--month-column-index);


      &.current-month {
        z-index: 75;
        background: linear-gradient($color-danger-dark, $color-danger-dark) no-repeat var(--day-amount-pixel)/var(--current-month-line-width) 100%;
        pointer-events: none;
        .current-month-with-overlay {
          background: linear-gradient($month-overlay-color, $month-overlay-color) no-repeat var(--month-overlay-2-left-offset)/var(--month-overlay-2-width) 100%,
          linear-gradient($color-danger-dark, $color-danger-dark) no-repeat var(--day-amount-pixel)/var(--current-month-line-width) 100%;

        }
      }

      &.overlay {
        z-index: 90;
        background: linear-gradient($month-overlay-color, $month-overlay-color) no-repeat var(--month-overlay-left-offset)/var(--month-overlay-width) 100%;
        pointer-events: none;
      }

      &.grid-box-left-transparent {
        border-left: 1px solid rgba(130, 130, 130, 15%);
      }

      &.grid-box-left {
        border-left: 1px solid $color-tertiary;
      }

      &.month-outer {
        width: 100%;
      }

    }

    .laege-header {
      @include sticky-white;
      display: flex;
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 1;
      align-items: center;
      border-right: $default-border;
      padding-left: $margin-m;
      z-index: 200;

      .pagination-header {
        font-size: 12px;
      }
    }


    .uddannelsessted-header {
      &.dummy {
        grid-template-columns: 1fr;
      }

      @include sticky-white;
      grid-row-start: var(--uddannelsessted-row-index);
      grid-column-start: 1;
      color: $text-color;
      background-color: var(--uddannelsessted-row-color);

      border-right: $default-border;
      z-index: 100;
    }

    .afdeling-header {
      @include sticky-white;
      grid-row-start: var(--afdeling-rowindex);
      background-color: var(--afdeling-row-color);
      grid-column-start: 1;
      color: $text-color;
      z-index: 100;
      border-right: $default-border;
    }

    .stilling-header {
      @include sticky-white;
      grid-row-start: var(--stilling-rowindex);
      grid-column-start: 1;
      color: $text-color;
      z-index: 100;
      border-right: $default-border;
    }

    .stilling {
      @include sticky-white;
      grid-row-start: var(--stilling-row-index);
      background-color: $color-white;
      cursor: pointer;
      border-right: $default-border;
    }

    .stilling-laege {
      z-index: 80;
      display: grid;
      grid-row-start: var(--stilling-row-index);
      grid-column-start: var(--forloeb-column-start);
      grid-column-end: var(--forloeb-column-end);
      position: relative;
      cursor: pointer;
      padding-left: var(--forloeb-column-l-pad);
      padding-right: var(--forloeb-column-r-pad);
      .laege-stilling-profile {
        &:hover {
          z-index: 1;
          box-shadow: 5px 5px 5px $color-primary-hover;
        }
        border: 1px solid $color-tertiary;
        background-color: $color-white;
        border-radius: 14px;
        margin: $margin-xs 0;
        color: #000;
        box-shadow: 1px 1px $margin-xs lightgrey;
      }
      .color-irrelevant-forloeb {
        background-color: $color-irrelevant-forloeb;
      }
      .user-avatar-subtle {
        background-color: $color-user-avatar-subtle;
      }
    }

    .uddannelsessted-wrapper {
      position: relative;
      display: grid;
    }

    .forloeb-chart-header {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: var(--header-column-start);
      grid-column-end: var(--header-column-end);
      background-color: $color-white;
      position: sticky;
      top: 0;
      display: grid;
      z-index: 85;
    }

    .forloeb-year {
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: var(--year-column-start);
      grid-column-end: var(--year-column-end);
      padding: $margin-s $margin-m 0 $margin-xs;
    }

    .forloeb-month {
      display: grid;
      grid-row-start: 2;
      grid-row-end: 3;
      grid-column-start: var(--month-column-index);
      justify-content: left;
      font-weight: 600;

      > span {
        align-self: center;
        padding: 0 0 $margin-s $margin-xs;
      }
    }

    .laege-profile {
      &.dummy {
        grid-template-columns: 1fr;
      }

      @include sticky-white;
      grid-row-start: var(--laege-row-index);
      grid-column-start: 1;
      color: $text-color;
      background-color: $color-white;
      border-right: $default-border;
      cursor: pointer;
      z-index: 100;

      &:nth-child(n+5):nth-last-child(-n+3) {
        padding-bottom: $margin-m;
      }

      .laege-avatar {
        display: flex;
        align-items: center;
        margin-left: $margin-m;
        padding-top: $margin-xs;
        padding-bottom: $margin-xs;

        .user-profile-avatar {
          height: 24px;
          width: 24px;

          .profile-initials {
            font-size: 11px;
          }
        }
      }

      .laege-name {
        margin-left: $margin-s;
      }

    }

    .pagination {
      @include sticky-white;
      justify-content: center;
      grid-row-start: var(--laege-row-index);
      grid-column-start: 1;
      background-color: $color-white;
      border-right: $default-border;
      z-index: 999;
      padding: $margin-m 0;
    }

    .laege-forloeb {
      z-index: 80;
      display: grid;
      grid-row-start: var(--laege-row-index);
      grid-column-start: var(--forloeb-column-start);
      grid-column-end: var(--forloeb-column-end);
      position: relative;
      cursor: pointer;
      padding-left: var(--forloeb-column-l-pad);
      padding-right: var(--forloeb-column-r-pad);

      &.forloeb-in-front {
        z-index: 81;
      }

      .laege-afdeling-icon {
        display: inline-block;
        background-color: $gray-lighter;
        color: #000;
        border-radius: 12px;
        height: 24px;
        width: 24px;
        margin-right: $margin-s;

        svg {
          height: 16px;
          width: 16px;
          margin: 0 0 $margin-xs $margin-xs;
        }
      }

      .laege-afdeling {
        background-color: $color-white;
        border-radius: 14px;
        margin: $margin-xs 0;
        color: #000;
        box-shadow: 1px 1px $margin-xs lightgrey;
        border: 1px solid $color-tertiary;

        &:hover {
          z-index: 1;
          box-shadow: 3px 3px 0.25rem $color-primary-hover;
          border: 1px solid $color-primary-hover;
        }
      }
    }

    .forloeb-list-empty-state {
      text-align: center;
      width: 42vw;
      margin-top: $margin-l;

      .spinner-with-text {
        margin-left: $margin-l;
      }
    }
  }
}

.forloeb-overview-row-spacer {
  @include sticky-white;
  border-right: $default-border;
  z-index: 100;
  grid-column-start: 1;
  grid-row-start: 0;
}

.forloeb-or-laege-tooltip-tip {
  z-index: 500;
}

.horizontal-line {
  z-index: 44;
  grid-row-start: var(--horizontal-line-row-index);
  grid-column-start: var(--column-start);
  grid-column-end: var(--column-end);
  border-top: 1px solid $color-tertiary;
}

.hover-line {
  z-index: 48;
  grid-row-start: var(--horizontal-line-row-index);
  grid-column-start: var(--column-start);
  grid-column-end: var(--column-end);
  &:hover {
    z-index: 50;
    background-color: $color-primary-hover;
  }
}

.horizontal-line-fat {
  z-index: 30;
  background-color: var(--line-row-color);
  grid-row-start: var(--horizontal-line-row-index);
  grid-column-start: var(--column-start);
  grid-column-end: var(--column-end);
}
