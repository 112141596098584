@import "src/content/styles/variables";
@import "src/content/styles/embla-variables";

.bruger-list-filter {
  min-width: 15rem;
  margin-bottom: 0.5rem;
}

#brugerListTable_wrapper .modal-filters.row {
  flex-wrap: nowrap;
}

.card.card-header-extension {
  margin-bottom: 0;
  border-bottom: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1);
}
