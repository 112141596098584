@import "src/content/styles/embla-variables";

.add-data-saet-container {
    position: relative;

    .add-data-saet-afdelinger {
        list-style: none;
        max-height: inherit;
        overflow: auto;
    }

    .add-data-saet-btn {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.graph-container {
  height: 30vh;
  padding: 2rem;
}

.graph-mobile-container {
  height: 30vh;
  min-height: 350px;
  padding: 2rem 0 2rem 0;
}

.datacard-border-radius {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.dataset-color-reduced-margin {
  margin-left: -1px;
}

.spoergeramme-question {
  width: 100%;
}

.dataset-color-border {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: 5px solid;
  width: 5px;
  height: inherit;
}

.dataset-list-item {
  border-top: $default-border;
}
.evaluering-card-padding {
  padding-left: 15px;
}

.sticky-header-container {
  position: relative;

  .sticky-header {
    position: sticky;
    top: 60px;
    background-color: white;
    z-index: 2;
  }
}

.max-uddannelsessted-width {
  max-width: 90%;
}

.uddannelsessteder-min-height-mobile {
  min-height: 400px;
}
