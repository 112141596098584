﻿.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pre-line {
  white-space: pre-line;
}
