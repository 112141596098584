@import "src/content/styles/embla-variables";

.left-btn {
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
}

.right-btn {
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}

.left-btn[disabled]:hover,
.right-btn[disabled]:hover {
    background-color: transparent;
    border-color: $color-gray-70;
    color: $color-gray-70;
}

.separator-btn {
  color: $color-gray-45 !important;;
  pointer-events: none;
}

.pagination-item {
  padding: 0 1em;

  &.active-pagination-btn {
    background-color: $primary;
    color: $color-white;
    box-shadow: none;
  }
}
