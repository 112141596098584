@import "src/content/styles/embla-variables";

.button-card-width {
  width: 240px;
}

.button-card-cube {
  min-height: 120px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}

.button-card {
  cursor: pointer;
}

.button-card-new {
  background: $color-tertiary;
}

.button-card-skabelon {
  background: $color-gray-95;
}

