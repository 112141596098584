@import "src/content/styles/embla-variables";

.planlaegCircle {
  align-self: center;
  border: $default-border;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  text-align: center;

  p {
    letter-spacing: 1px;
  }
}

.kompetence-full-text {
  white-space: pre-wrap;
}

.visning-kompetence-btngroup{
  margin-left: auto;
}
