// Global print-styling:
@media print {
  :root {
    // Set default page-size:
    page: portrait-A4;
  }

  body {
    overflow: hidden !important; //Dont show scrollbars

    // Show colors on print
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  
  /* Hide all navigation, headers, titlebars, etc */
  nav.public-top-menu, nav.public-top-menu *, //Hide main top-navigation-bar
  body .left-menu, body .left-menu *, //Hide left-menu
  .bottom-menu, //Hide bottom-menu (Mobile)
  .title-bar //Hide global title-bar
  {
    display: none !important;
  }

  // Shrink spacing so we can see as much content as possible (Print has its own default margins)
  body .page-content, 
  body .body-content {
    padding: 0 !important;
    margin: 0 !important;
  }
}

// Setup page-styles for use in specific printable pages:

@page landscape-A3 {
  size: A3 landscape !important;
}

@page landscape-A3-no-margin {
  size: A3 landscape !important;
  margin: 0;
}

@page portrait-A4 {
  size: A4 portrait !important;
}

@page landscape-A4-no-margin {
  size: A3 landscape !important;
  margin: 0;
}