@import "src/content/styles/variables";

.accordion-card {
    display: block;
    margin-bottom: 0;

    .accordion-card-header {
        display: block;
        padding: 0.25rem;

        .accordion-item-button {
            &:hover {
              border-color: transparent;
            }
            &:active {
              border-color: transparent;
            }
            &:focus {
              border-color: transparent;
            }

            .embla-icon {
                fill: $color-gray-15;
            }

            &.btn-link:active:not(:disabled):not(.disabled) {
                border: none;
                background-color: transparent;
                color: $color-gray-15;

                .embla-icon {
                    fill: $color-gray-15;
                }

            }

            &.btn:not(:disabled):not(.disabled):active {
                box-shadow: none;
            }
        }
    }
}
