﻿// without bottommenu
.body-content {
  .height-100-vh {
    height: calc(100vh - 175px);
  }

  .height-75-vh {
    height: calc(82vh - 175px);
  }

  .height-80-vh {
    height: calc(84vh - 175px);
  }

  .height-85-vh {
    height: calc(90vh - 175px);
  }
}

.body-content.titlebarShown {
  .height-100-vh {
    height: calc(100vh - 272px);
  }
}


// with bottom menu
.body-content.bottommenuShown {
  .height-100-vh {
    height: calc(100vh - 114px);
  }
}

.body-content.titlebarShown.bottommenuShown {
  .height-100-vh {
    height: calc(100vh - 187px);
  }
}
