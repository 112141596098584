@import "src/content/styles/embla-variables";
@import "src/content/styles/variables";

.color-primary {
  background-color: $color-primary;
}

.color-anonymiseret-forloeb {
  background-color: $color-anonymiseret-forloeb !important;
}
.color-fravaer-forloeb {
  background-color: $color-fravaer-forloeb !important;
}
.color-irrelevant-forloeb {
  background-color: $color-irrelevant-forloeb !important;
}
.color-manuelt-afsluttet-forloeb {
  background-color: $warning-background-color !important;
}
.color-aktivt-forloeb {
  background-color: $color-active-forloeb !important;
}

.color-shaded-forloeb {
  --forloeb-shaded-opacity: .8;
  --forloeb-shaded-gradient-direction: -45deg;
  --forloeb-shaded-gradient-light-stripe-width: 10px;
  --forloeb-shaded-gradient-dark-stripe-width: 20px;

  &.color-shade-forloeb-huge-lines {
    --forloeb-shaded-gradient-light-stripe-width: 20px;
    --forloeb-shaded-gradient-dark-stripe-width: 40px;
  }

  opacity: var(--forloeb-shaded-opacity);

  // Fix so that border-colors on buttons can be seen when forloeb is shaded:
  .btn.btn-transparent, .forloeb-uddannelse-circle {
    border-color: $color-gray-70;
  }

  background: repeating-linear-gradient(
    var(--forloeb-shaded-gradient-direction), /* Direction of stripes */
    $color-gray-95, /* Color of first stripe */
    $color-gray-95 var(--forloeb-shaded-gradient-light-stripe-width), /* Width of first stripe */
    $color-gray-85 var(--forloeb-shaded-gradient-light-stripe-width), /* Start of next stripe (same as previous stripe width) */
    $color-gray-85 var(--forloeb-shaded-gradient-dark-stripe-width),/* Width of next stripe */
  );

  &.color-fravaer-forloeb {
    background: repeating-linear-gradient(
      var(--forloeb-shaded-gradient-direction),
      $color-fravaer-forloeb-light,
      $color-fravaer-forloeb-light var(--forloeb-shaded-gradient-light-stripe-width),
      $color-fravaer-forloeb var(--forloeb-shaded-gradient-light-stripe-width),
      $color-fravaer-forloeb var(--forloeb-shaded-gradient-dark-stripe-width)
    );
  }

  &.color-irrelevant-forloeb {
    background: repeating-linear-gradient(
      var(--forloeb-shaded-gradient-direction),
      $color-irrelevant-forloeb,
      $color-irrelevant-forloeb var(--forloeb-shaded-gradient-light-stripe-width),
      $color-gray-85 var(--forloeb-shaded-gradient-light-stripe-width),
      $color-gray-85 var(--forloeb-shaded-gradient-dark-stripe-width)
    );
  }

  &.color-manuelt-afsluttet-forloeb {
    background: repeating-linear-gradient(
      var(--forloeb-shaded-gradient-direction),
      $warning-background-color,
      $warning-background-color var(--forloeb-shaded-gradient-light-stripe-width),
      $color-gray-85 var(--forloeb-shaded-gradient-light-stripe-width),
      $color-gray-85 var(--forloeb-shaded-gradient-dark-stripe-width)
    );
  }

  &.active-forloeb, &.color-aktivt-forloeb {
    background: repeating-linear-gradient(
      var(--forloeb-shaded-gradient-direction),
      $color-active-forloeb,
      $color-active-forloeb var(--forloeb-shaded-gradient-light-stripe-width),
      $color-active-forloeb-dark var(--forloeb-shaded-gradient-light-stripe-width),
      $color-active-forloeb-dark var(--forloeb-shaded-gradient-dark-stripe-width)
    );
  }

}

.quickguide-icon-uddannelseoverview {
  .embla-icon {
    fill: $color-primary;
    opacity: 0.7;
  }
}

.badge-danger.color-ikkegodkendt-ikkeevalueret {
  background-color: $color-ikkegodkendt-ikkeevalueret;
}
