﻿@import "src/content/styles/variables";

.card {
  &.card-no-margin{
    margin-bottom: 0;
  }
  &.card-small-margin{
    margin-bottom: 8px;
  }
}

.card-header-text-padding  {
    padding-top: $padding-m;
    padding-bottom: $padding-m;
    padding-right: $padding-m;
}

.card-list-custom{
  &.container-height {
    height: calc(100vh - 314px);
  }

  &.row-min-height {
    min-height: 0;
  }

  &.column-height {
    height: 100%;
  }

  &.style{
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &.body-flex {
    flex: 0 0 auto;
  }
}

.card-list-modal {
  > .card-list {
    padding-left: 0;
    padding-right: 0;
  }

  > .card-list-border {
    margin-left: 0;
    margin-right: 0;
  }

  .card-list {
    &:first-child {
      padding-top: 0;
    }
  }
}

.card-list-box {
  padding: 8px 16px;

  border-bottom: $default-border;
}

.card-list {
  display: flex;
  padding: 16px;

  > :first-child {
    flex-grow: 1;
    min-width: 1px;
  }

  .card-list-icon {
    margin-left: -4px;
  }

  .card-list-calender-icon {
    margin-left: -2px;
  }
}

.card-list-border {
  border-bottom: $default-border;
  margin-left: 16px;
  margin-right: 16px;

  &:last-child {
    border-bottom: 0;
  }
}

.card-list-border-bot {
  border-bottom: 1px $color-gray-85 solid;
}

.card-list-scroll {
  overflow-x: auto;
  overflow-y: scroll;
}

.card-y-list-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
}

.card-list-active {
  background-color: rgba(33, 62, 130, 0.1);
  border-left: 3px solid $color-primary;

  &.card-list {
    padding: 20px 20px 20px 17px;
  }
}
