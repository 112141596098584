svg.embla-icon.small {
    width: 16px;
    height: 16px;
}

svg.embla-icon.medium {
    width: 20px;
    height: 20px;
}

svg.embla-icon.large {
    width: 24px;
    height: 24px;
}

svg.embla-icon.huge {
    width: 28px;
    height: 28px;
}

.embla-icon.white {
  fill: white;
}
