@import "src/content/styles/embla-variables";

.notification-list-item {
  background-color: white;

  &.not-seen {
    background-color: $color-tertiary-dark;

    .notification-list-item-body {
      .afsender-badge {
        .notification-type-icon {
          background-color: $color-tertiary-dark;
        }
      }
    }
  }

  &:hover {
    background-color: $color-tertiary-light;
    cursor: pointer;

    .notification-list-item-body {
      .afsender-badge {
        .notification-type-icon {
          background-color: $color-tertiary-light;
        }
      }
    }
  }

  .notification-list-item-body {
    display: flex;
    padding: 1rem;

    .afsender-badge {
      margin-right: 1rem;
      position: relative;

      .notification-type-icon {
        position: absolute;
        right: -15%;
        bottom: -15%;
        background-color: white;
        border-radius: 50%;
      }
    }
  }

  hr.item-divider {
    width: 95%;
    margin-block: 0;
  }
}

